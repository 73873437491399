import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Utils } from '../utils';

import { ToggleOptions } from './toggle.model';

declare const $: any;

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Input() options: ToggleOptions;
  @Input() value: any;

  @Output() changeValue: EventEmitter<any>;

  defaultOptions: ToggleOptions;

  constructor() {
    this.changeValue = new EventEmitter<any>();
    this.defaultOptions = {
      id: 'toggle',
      labels: {
        left: {
          text: '',
          value: false
        },
        right: {
          text: '',
          value: true
        },
        style: {
          color: '#596275',
          fontSize: '24px',
          lineHeight: '24px',
          fontWeight: '400'
        }
      }
    };
  }

  ngOnInit() {
    this.options = Utils.mergeObject(this.defaultOptions, this.options);

    setTimeout(() => {
      $(`#${this.options.id}`).checkbox({
        onChecked: () => {
          this.changeValue.emit(this.options.labels.right.value);
        },
        onUnchecked: () => {
          this.changeValue.emit(this.options.labels.left.value);
        },
      });

      if (this.value === undefined) {
        $(`#${this.options.id}`).checkbox('set indeterminate');
      } else if (this.value === this.options.labels.right.value) {
        $(`#${this.options.id}`).checkbox('check');
      } else if (this.value === this.options.labels.left.value) {
        $(`#${this.options.id}`).checkbox('uncheck');
      }
    }, 0);
  }

  setValue(value: any) {
    if (value) {
      $(`#${this.options.id}`).checkbox('check');
      this.value = this.options.labels.right.value;
    } else {
      $(`#${this.options.id}`).checkbox('uncheck');
      this.value = this.options.labels.left.value;
    }
  }
}
