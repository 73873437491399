import { Component, OnInit, Input, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { UserService } from '../../../api';

declare const $: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  passwordChanged: boolean;

  constructor(
    private userService: UserService
  ) {

  }

  ngOnInit() {
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      password:   new FormControl(null, [
        Validators.required,
        // Validators.minLength(8),
        // Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
      ]),
      confirmPassword: new FormControl(null, [Validators.required])
    }, {
      validator: this.checkPasswords
    });
    this.passwordChanged = false;
  }

  close() {
    $('.ui.modal.reset-password-modal').modal('hide');
    this.passwordChanged = false;
    this.formFields.reset();
  }

  reset() {
    if (this.formFields.invalid) {
      return;
    }

    this.formError = undefined;

    this.userService.resetPassword(this.formFields.value).toPromise().then(response => {
      this.passwordChanged = true;
      this.formFields.reset();
    });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.reset();
    }
  }

  private checkPasswords(group: FormGroup) {
    const passControl = group.controls.password;
    const confirmPassControl = group.controls.confirmPassword;
    return passControl.value === confirmPassControl.value ?
      confirmPassControl.setErrors(null) : confirmPassControl.setErrors({ notEquivalent: true });
  }

}
