import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';

import { Utils } from '../../utils';

import { WorkCenterWithAreas } from '../search-result.model';

@Component({
  selector: 'app-search-result-card',
  templateUrl: './search-result-card.component.html',
  styleUrls: ['./search-result-card.component.scss']
})
export class SearchResultCardComponent implements OnInit, OnChanges {
  @Input() item: WorkCenterWithAreas;
  @Input() size: 'big' | 'small';
  @Output() seeAvailableAreasClicked: EventEmitter<string>;

  schedulesDisplayValue: string[];
  workplaceTypes: string[];

  constructor() {
    this.seeAvailableAreasClicked = new EventEmitter();
  }

  ngOnInit() {
    this.workplaceTypes = [];
    this.item.minPrice = this.item.areas[0].tariffs[0].price;
    this.item.areas.forEach(area => {
      area.tariffs.forEach(tariff => {
        if (tariff.price < this.item.minPrice) {
          this.item.minPrice = tariff.price;
        }
      });

      const workplaceTypeName = area.workplaceType.name;
      if (!this.workplaceTypes.includes(workplaceTypeName)) {
        this.workplaceTypes.push(workplaceTypeName);
      }
    });

    let briefText = this.item.aboutText ? this.item.aboutText.slice(0, 301) : '';
    briefText += (briefText.length < 301) ? '' : '...';
    this.item.briefText = briefText;


    this.schedulesDisplayValue = Utils.getSchedulesDisplayValue(this.item.schedules);
  }

  ngOnChanges(changes: any) {
  }

  onSeeAvailableAreasClicked() {
    this.seeAvailableAreasClicked.emit(this.item.id);
  }
}
