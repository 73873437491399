import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as ApiModels from '../../../api';
import { AreaService, PhotoService, WorkplaceService } from '../../../api';

import { AreaStatus } from '../area.model';

@Component({
  selector: 'app-area-view',
  templateUrl: './area-view.component.html',
  styleUrls: ['./area-view.component.scss']
})
export class AreaViewComponent implements OnInit {
  @Input() area: ApiModels.Area & ApiModels.AreaWithWorkplacesAndTariffs;
  @Input() schedules: ApiModels.ScheduleInfo[];
  @Input() equipments: ApiModels.Equipment[];
  @Input() size: 'small' | 'big';
  @Output() showEditForm: EventEmitter<void>;

  workplaces: ApiModels.WorkplaceIdAndNumber[];
  photoIds: string[];

  currentStatus: AreaStatus;
  basicStatuses: AreaStatus[] = [
    {
      id: 0,
      name: 'Удалено',
      color: '#BDBDBD',
    },
    {
      id: 1,
      name: 'Активно',
      color: '#0AB21B',
    },
    {
      id: 2,
      name: 'На модерации',
      color: '#41BBC9',
    },
    {
      id: 3,
      name: 'Отклонено',
      color: '#EB595E',
    },
    {
      id: 4,
      name: 'Изменено',
      color: '#FFDB72',
    },
    {
      id: 5,
      name: 'Не активно',
      color: '#BDBDBD',
    },
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private areaService: AreaService,
    private photoService: PhotoService,
    private workplaceService: WorkplaceService
  ) {
    this.size = 'big';
    this.showEditForm = new EventEmitter<void>();
   }

  ngOnInit() {
    this.workplaces = this.area.workplaces;

    this.currentStatus = this.basicStatuses.find((basicStatus) => basicStatus.id === this.area.status);
  }

  onDeleteWorkplace(id: string) {
    this.workplaceService.deleteWorkplace(id).toPromise().then(response => {
      this.workplaces = this.workplaces.filter(workplace => workplace.id !== id);
    });
  }

  onAddWorkplace() {
    // const newWorkplace: ApiModels.WorkplaceAdd = {
    //   areaId: this.area.id
    // };
    // this.workplaceService.createWorkplace(newWorkplace).toPromise().then(response => {
    //   console.log(response);
    //   this.getWorkplacesAndTariffs();
    //   // this.workplaces.push({ workplaceNumber: workplaceNumberMax + 1 });
    // });

    const newWorkplace: ApiModels.WorkplaceForAreaAdd = {
      areaId: this.area.id,
      workplacesAmount: 1
    };
    this.workplaceService.createWorkplacesForArea(newWorkplace).toPromise().then(response => {
      console.log(response);
      this.getWorkplacesAndTariffs();
      // this.workplaces.push({ workplaceNumber: workplaceNumberMax + 1 });
    });
  }

  onShowAreaPage() {
    const organisationId = this.activatedRoute.snapshot.parent.parent.paramMap.get('id');
    const workCenterId = this.activatedRoute.snapshot.parent.paramMap.get('id');
    this.router.navigateByUrl(`/profile/organisation/${organisationId}/workcenter/${workCenterId}/area/${this.area.id}`);
  }

  onShowBidSize() {
    this.getAreaInfo().then(() => {
      this.getAreaPhotos().then(() => {
        this.size = 'big';
      });
    });
  }

  onShowEditForm() {
    this.showEditForm.emit();
  }

  private getAreaInfo() {
    return this.areaService.getAreaById(this.area.id).toPromise().then(response => {
      this.area = {
        ...this.area,
        ...response
      };
    });
  }

  private getWorkplacesAndTariffs() {
    return this.areaService.getAreaWithWorkplaceNumbersandTariffsByAreaIds([this.area.id]).toPromise().then(response => {
      this.area = {
        ...this.area,
        ...response
      };
      this.workplaces = this.area.workplaces;
    });
  }

  private getAreaPhotos() {
    return this.photoService.getPhotosForGUID(this.area.id).toPromise().then(response => {
      this.photoIds = response.map(item => item.id);
    });
  }

}
