import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import * as ApiModels from '../../../../api';
import { AccountService, OrganisationService } from '../../../../api';

import { AuthService } from '../../../auth';

import { CreateProfileModel, UserType } from '../registration.model';

@Component({
  selector: 'app-registration-password',
  templateUrl: './registration-password.component.html',
  styleUrls: ['./registration-password.component.scss']
})
export class RegistrationPasswordComponent implements OnInit {
  @Input() userType: UserType['name'];
  @Input() userProfile: CreateProfileModel;
  @Input() organisationProfile: ApiModels.OrganisationAdd;
  @Output() registrationEnded: EventEmitter<string>;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private organisationService: OrganisationService
  ) {
    this.registrationEnded = new EventEmitter();
  }

  ngOnInit() {
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      password: new FormControl(null),
      confirmPassword: new FormControl(null)
    });
  }

  onRegisterButtonClick() {
    this.userProfile.password = this.formFields.get('password').value;
    this.accountService.postCreateProfile(this.userProfile).toPromise().then(userResponse => {
      this.authUser(userResponse.refreshToken, userResponse.token);
      if (this.userType === 'owner') {
        this.organisationService.createOrganisation(this.organisationProfile).toPromise().then(organisationResponse => {
          this.registrationEnded.emit(organisationResponse.id);
        }).catch(error => {

        });
      } else {
        this.registrationEnded.emit();
      }
    }).catch(error => {

    });
  }

  authUser(refreshToken, token) {
    this.authService.setRefreshToken(refreshToken);
    this.authService.setToken(token);
  }
}
