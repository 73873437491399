import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';

import * as ApiModels from '../../api';
import { WorkCenterService, RentService } from '../../api';

import { Utils } from '../shared/utils';
import { ToggleOptions } from '../shared/toggle';
import { ReservationPickerOptions } from '../shared/reservation-picker';

declare const $: any;

@Component({
  selector: 'app-workcenter-search',
  templateUrl: './workcenter-search.component.html',
  styleUrls: ['./workcenter-search.component.scss']
})
export class WorkCenterSearchComponent implements OnInit, AfterViewInit {
  workCenter: ApiModels.WorkCenterWithAreaEqupmentsTariffsWorkplacesRents;
  workCenterSchedules: string[];
  loadingWorkCenterAreas: boolean;

  filterParams: ApiModels.GetInfoParamsExtended;
  tariffToggleOptions: ToggleOptions;

  reservationPickerVisible: boolean;
  reservationPickerOptions: ReservationPickerOptions;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private workCenterService: WorkCenterService,
    private rentService: RentService
  ) {
    this.filterParams = { id: null };
    this.workCenterSchedules = [];
  }

  ngOnInit() {
    this.filterParams.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.activatedRoute.queryParams.subscribe((queryParam) => {
      this.filterParams = {
        ...this.filterParams,
        date: queryParam.date ? new Date(queryParam.date)  : undefined,
        timeFrom: queryParam.timeFrom ?  queryParam.timeFrom : undefined,
        timeTo: queryParam.timeTo ?  queryParam.timeTo : undefined,
        tariffType: queryParam.tariffType ? +queryParam.tariffType : undefined,
        workplaceTypes: queryParam.workplaceTypes ? JSON.parse(queryParam.workplaceTypes) : undefined
      };

      this.getWorkCenterInfo();
    });

    this.tariffToggleOptions = {
      labels: {
        left: {
          text: 'По часам',
          value: 0
        },
        right: {
          text: 'По дням',
          value: 1
        },
        style: {
          fontSize: '18px',
          lineHeight: '22px'
        }
      }
    };

    this.reservationPickerVisible = false;
  }

  ngAfterViewInit() {
    $('.ui.dropdown').dropdown();
  }

  changeDate(date) {
    this.loadingWorkCenterAreas = true;
    this.filterParams.date = date;
    this.updateWorkCenterAreas();
  }

  onChangeTariffType(value: number) {
    this.loadingWorkCenterAreas = true;
    this.filterParams.tariffType = value;
    this.updateWorkCenterAreas();
  }

  showRouteDetailsModal() {
    $('.ui.modal.modal_route-details').modal('show');
  }

  hideRouteDetailsModal() {
    $('.ui.modal.modal_route-details').modal('hide');
  }

  getAreaMinPrice(areaTariffs: ApiModels.TariffView[]) {
    if (!areaTariffs || !areaTariffs.length) {
      return;
    }

    let minPrice = areaTariffs[0].price;

    areaTariffs.forEach((tariff) => {
      if (tariff.price < minPrice) {
        minPrice = tariff.price;
      }
    });

    return minPrice;
  }

  showReservationPickerModal(areaId: string) {
    this.reservationPickerVisible = true;

    setTimeout(() => {
      this.reservationPickerOptions = {
        areaId,
        tariffType: this.filterParams.tariffType,
        date: this.filterParams.date
      };

      $('.ui.modal.modal_reservation-picker').modal({
        onHidden: () => {
          this.reservationPickerVisible = false;
        }
      });

      $('.ui.modal.modal_reservation-picker').modal('show');
    }, 0);
  }

  reserved(workplaceId: string) {
    if (
      !this.filterParams.date ||
      (this.filterParams.tariffType === 0 && (!this.filterParams.timeFrom || !this.filterParams.timeTo))
    ) {
      return;
    }

    let dateStart: Date;
    let dateEnd: Date;
    if (this.filterParams.tariffType === 0) {
      const date = formatDate(this.filterParams.date, 'MM.dd.yyyy', 'ru');
      dateStart = new Date(`${date} ${this.filterParams.timeFrom}:00`);
      dateEnd = new Date(`${date} ${this.filterParams.timeTo}:00`);
    } else {
      const date = this.filterParams.date;
      dateStart = new Date(date);
      dateEnd = new Date(date.setDate(date.getDate() + 1));
    }

    this.rentService.rentCreate([{
      workplaceId,
      tariffType: this.filterParams.tariffType,
      dateStart,
      dateEnd
    }]).toPromise().then(rentIds => {
      this.router.navigate([ '/reservation/confirmation', rentIds[0] ]);
    }).catch(error => {
      console.log(error);
    });
  }

  private getWorkCenterInfo() {
    this.workCenterService.getInfoByIdExtendedOld(this.filterParams)
      .toPromise().then((data) => {
        this.workCenter = data;
        this.workCenterSchedules = Utils.getSchedulesDisplayValue(data.schedules);

        setTimeout(() => {
          $('.ui.accordion').accordion();
        }, 0);
    });
  }

  private updateWorkCenterAreas() {
    this.workCenterService.getInfoByIdExtendedOld(this.filterParams)
      .toPromise().then((data) => {
        this.workCenter.areas = data.areas;
        this.loadingWorkCenterAreas = false;

        setTimeout(() => {
          $('.ui.accordion').accordion();
        }, 0);
    });
  }
}
