import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { PhotoInfo } from '../../../api';

@Component({
  selector: 'app-photo-loader',
  templateUrl: './photo-loader.component.html',
  styleUrls: ['./photo-loader.component.scss']
})
export class PhotoLoaderComponent implements OnInit {
  @Input() photos: PhotoInfo[];
  @Output() onLoadPhoto: EventEmitter<any>;
  @Output() onDeletePhoto: EventEmitter<PhotoInfo>;

  constructor() {
    this.photos = [];
    this.onLoadPhoto = new EventEmitter<any>();
    this.onDeletePhoto = new EventEmitter<PhotoInfo>();
  }

  ngOnInit() {
  }

  addPhoto(event) {
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.onLoadPhoto.emit(file);
    }
  }

  removePhoto(photo: PhotoInfo) {
    this.onDeletePhoto.emit(photo);
  }
}
