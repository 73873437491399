import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import * as ApiModels from '../../../api';
import { WorkCenterService } from '../../../api';

import { Utils } from '../../shared/utils';

import { ScheduleWeekDay, WorkCenterStatus } from '../workcenter.model';

declare const $: any;

@Component({
  selector: 'app-workcenter-view',
  templateUrl: './workcenter-view.component.html',
  styleUrls: ['./workcenter-view.component.scss']
})
export class WorkCenterViewComponent implements OnInit, OnChanges {
  @Input() workCenter: ApiModels.WorkCenterView;
  @Input() workCenterPhotosIds: string[];
  @Output() showEditForm: EventEmitter<void>;

  organisationId: string;
  hasActiveRents: boolean ;
  activationText: string;

  weekDays: ScheduleWeekDay[];
  scheduleToDisplay: string[];
  basicStatuses: WorkCenterStatus[] = [
    {
      id: 0,
      name: 'Удален',
      color: '#BDBDBD',
    },
    {
      id: 1,
      name: 'Активный',
      color: '#0AB21B',
    },
    {
      id: 2,
      name: 'На модерации',
      color: '#41BBC9',
    },
    {
      id: 3,
      name: 'Отклонен',
      color: '#EB595E',
    },
    {
      id: 4,
      name: 'Изменен',
      color: '#FFDB72',
    },
    {
      id: 5,
      name: 'Не активный',
      color: '#BDBDBD',
    },
  ];
  currentStatus: WorkCenterStatus;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private workCenterService: WorkCenterService,
  ) {
    this.showEditForm = new EventEmitter();
    this.activationText = 'Снять с публикации';
    this.workCenter = null;
    this.hasActiveRents = false;
    this.weekDays = [
      { number: 1, value: 'Пн' },
      { number: 2, value: 'Вт' },
      { number: 3, value: 'Ср' },
      { number: 4, value: 'Чт' },
      { number: 5, value: 'Пт' },
      { number: 6, value: 'Сб' },
      { number: 7, value: 'Вс' }
    ];
    this.workCenterPhotosIds = [];
    this.scheduleToDisplay = [];
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('ngOnChanges');

    if (changes.workCenter && this.workCenter) {
      this.organisationId = this.workCenter.organisationId;
      this.scheduleToDisplay = Utils.getSchedulesDisplayValue(this.workCenter.schedules);
      this.currentStatus = this.basicStatuses.find((basicStatus) => basicStatus.id === this.workCenter.status);

      if (this.workCenter.status === 5) {
        this.activationText = 'Активировать';
      }

      // if (this.workCenter && this.workCenter.areas) {
      //   this.workCenter.areas.forEach( area => {
      //     area.workplaces.forEach( workplace => {
      //       workplace.rents.forEach(rent => {
      //         if (rent.status === 1) {
      //           this.hasActiveRents = true;
      //         }
      //       });
      //     });
      //   });
      // }
    }
  }

  checkActiveRentsAndShowModal() {
    if (this.hasActiveRents) {
      this.showModal('delete-denied');
    } else {
      this.showModal('delete-allowed');
    }
  }

  onEditButtonClicked() {
    this.showEditForm.emit();
  }

  unpublishWorkCenterShow() {
    this.showModal('delete-allowed');
  }

  unpublishWorkCenter() {
    if (this.workCenter.status === 5) {
      this.workCenterService.workCenterActivate({id: this.workCenter.id}).toPromise().then(response => {
        this.workCenter.status = 2;
        this.activationText = 'Снять с публикации';
        this.hideModal();
      });
    } else {
      this.workCenterService.workCenterDeactivate({id: this.workCenter.id}).toPromise().then(response => {
        this.workCenter.status = 5;
        this.activationText = 'Активировать';
        this.hideModal();
      });
    }
  }

  deleteWorkCenter() {
    this.workCenterService.workCenterDelete({id: this.workCenter.id}).toPromise().then(response => {
      this.hideModal();
      this.router.navigate(['/profile/organisation', this.organisationId]);
    });
  }

  goToWorkCenterRents() {
    this.router.navigate(['/profile/organisation', this.organisationId, 'reservations']);
  }

  goToAreaUpsert() {
    this.router.navigateByUrl(`/profile/organisation/${this.organisationId}/workcenter/${this.workCenter.id}/area/add`);
  }

  showModal(modalClass: string) {
    if (this.workCenter.status === 5) {
      this.unpublishWorkCenter();
    } else {
      $(`.ui.modal.${modalClass}`).modal('show');
    }
  }

  hideModal() {
    $(`.ui.modal`).modal('hide');
  }
}
