import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { ToggleOptions } from '../../toggle/toggle.model';

import { UserType } from '../registration.model';

declare const $: any;

@Component({
  selector: 'app-registration-start',
  templateUrl: './registration-start.component.html',
  styleUrls: ['./registration-start.component.scss']
})
export class RegistrationStartComponent implements OnInit {
  @Input() userType: UserType['name'];
  @Output() showLogin: EventEmitter<void>;
  @Output() userTypeSet: EventEmitter<UserType['name']>;

  userTypeToggleOptions: ToggleOptions;

  constructor() {
    this.userTypeSet = new EventEmitter();
    this.showLogin = new EventEmitter();
  }

  ngOnInit() {
    if (this.userType === undefined) this.userType = 'renter'; // TODO delete
    this.userTypeToggleOptions = {
      id: 'userTypeToggle',
      labels: {
        left: {
          text: 'Арендовать',
          value: 'renter'
        },
        right: {
          text: 'Сдать',
          value: 'owner'
        },
        style: {
          color: '#596275',
          fontSize: '24px',
          lineHeight: '24px',
          fontWeight: '400'
        }
      }
    };
  }

  toggleChanged(value: UserType['name']) {
    this.userType = value;
  }

  onNextButtonClick() {
    this.userTypeSet.emit(this.userType);
  }

  onLoginShow() {
    this.showLogin.emit();
  }
}
