import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { formatDate } from '@angular/common';

import * as ApiModels from '../../../api';

export interface EditItem {
  columnValue?: number;
  rowValue?: number;
  price?: number;
}

@Component({
  selector: 'app-tariff-board',
  templateUrl: './tariff-board.component.html',
  styleUrls: ['./tariff-board.component.scss']
})
export class TariffBoardComponent implements OnInit, OnChanges {
  @Input() mode: 'view' | 'edit';
  @Input() tariffType: number;
  @Input() dateStart: Date;
  @Input() dateEnd: Date;
  @Input() schedules: ApiModels.ScheduleInfo[];
  @Input() tariffs: ApiModels.TariffInfo[];

  @Output() changeTariffs: EventEmitter<ApiModels.TariffInfo[]>;

  columns: any;
  rows: any;
  editItem: EditItem;

  daysOfWeek = [{
    id: 1,
    name: 'Понедельник',
    shortName: 'ПН'
  }, {
    id: 2,
    name: 'Вторник',
    shortName: 'ВТ'
  }, {
    id: 3,
    name: 'Среда',
    shortName: 'СР'
  }, {
    id: 4,
    name: 'Четверг',
    shortName: 'ЧТ'
  }, {
    id: 5,
    name: 'Пятница',
    shortName: 'ПТ'
  }, {
    id: 6,
    name: 'Суббота',
    shortName: 'СБ'
  }, {
    id: 7,
    name: 'Воскресенье',
    shortName: 'ВС'
  }];

  constructor() {
    this.changeTariffs = new EventEmitter();
  }

  ngOnInit() {


  }

  ngOnChanges(changes: any) {
    if (changes.schedules && this.schedules) {
      this.schedules = this.schedules.sort((prev, next) => prev.dayOfWeek - next.dayOfWeek);
      this.UpdateData();
    }

    if (changes.tariffs && this.tariffs) {
      this.tariffs = this.tariffs.sort((prev, next) => prev.weekDay - next.weekDay);
    }
  }

  getItemPrice(columnValue: number, rowValue: number): number {
    let price = 0;
    const itemTariff = this.tariffs.find(tariff => {
      if (this.tariffType) {
        return tariff.weekDay === columnValue;
      } else {
        return tariff.weekDay === rowValue && tariff.hour === columnValue;
      }
    });
    if (itemTariff) {
      price = itemTariff.price;
    }

    return price;
  }

  getItemDisplayPrice(columnValue: number, rowValue: number): string {
    const price = this.getItemPrice(columnValue, rowValue);
    const displayPrice = price ? (price + ' ₽') : '—';

    return displayPrice;
  }

  isExcludedFromSchedule(columnValue: number, rowValue: number): boolean {
    if (this.tariffType) {
      return false;
    }

    const findIndex = this.schedules.findIndex(schedule => {
      const startHour = +schedule.startHour.split(':').shift();
      const endHour = +schedule.endHour.split(':').shift();
      return schedule.dayOfWeek === rowValue && startHour <= columnValue && columnValue <= endHour;
    });

    return findIndex === -1;
  }

  onItemEdit(columnValue: number, rowValue: number) {
    const price = this.getItemPrice(columnValue, rowValue) || null;
    this.editItem = {columnValue, rowValue, price};

    setTimeout(() => {
      const editItemHTMLElement = document.getElementById('editItem') as HTMLInputElement;
      if (editItemHTMLElement) {
        editItemHTMLElement.focus();
        editItemHTMLElement.select();
      }
    }, 0);
  }

  isItemEdit(columnValue: number, rowValue: number) {
    return this.editItem && this.editItem.columnValue === columnValue && this.editItem.rowValue === rowValue;
  }

  private setItemPrice(editItem: EditItem) {
    if (this.tariffType) {
      const weekDay = editItem.columnValue;
      const price = editItem.price;

      const existingTariff = this.tariffs.find(tariff => tariff.weekDay === weekDay);
      if (existingTariff) {
        existingTariff.price = price;
      } else if (price) {
        this.tariffs.push({
          price,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          weekDay,
          tariffType: this.tariffType
        });
      }
    } else {
      const hour = editItem.columnValue;
      const weekDay = editItem.rowValue;
      const price = editItem.price;

      const existingTariff = this.tariffs.find(tariff => tariff.weekDay === weekDay && tariff.hour === hour);
      if (existingTariff) {
        existingTariff.price = price;
      } else if (price) {
        this.tariffs.push({
          price,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          hour,
          weekDay,
          tariffType: this.tariffType
        });
      }
    }
  }

  private UpdateData() {
    this.columns = this.getColumns(this.tariffType, this.schedules);
    this.rows = this.getRows(this.tariffType, this.daysOfWeek);
  }

  private getRows(tariffType: number, daysOfWeek: any) {
    let labels: any[] = [];

    if (tariffType) {
      labels.push({ id: 1});
    } else {
      labels = daysOfWeek;
    }

    return labels;
  }

  private getColumns(tariffType: number, schedules: ApiModels.ScheduleInfo[]): any[] {
    const labels: any[] = [];
    const date = new Date(2020, 1, 1, 0, 0, 0, 0);

    if (tariffType) {
      for (let i = 1; i <= 7; i++) {
        const dayOfWeek = this.daysOfWeek.find(day => day.id === i).name;
        const findSchedule = schedules.find(item => item.dayOfWeek === i);
        if (findSchedule) {
          const startHour = findSchedule.startHour.split(':').slice(0, 2).join(':');
          const endHour = findSchedule.endHour.split(':').slice(0, 2).join(':');
          const schedule = findSchedule ? `${startHour} - ${endHour}` : 'не указан';

          labels.push({
            value: i,
            displayValue: {
              dayOfWeek,
              schedule
            }
          });
        }
      }
    } else {
      let start = 24;
      let end = 0;

      schedules.forEach(schedule => {
        const startHour = +schedule.startHour.split(':').shift();
        const endHour = +schedule.endHour.split(':').shift();
        if (startHour < start) {
          start = startHour;
        }
        if (endHour > end) {
          end = endHour;
        }
      });

      for (let i = start; i <= end; i++) {
        date.setHours(i);
        labels.push({
          value: i,
          displayValue: {
            hour: formatDate(date, 'HH:mm', 'ru')
          }
        });
      }
    }

    return labels;
  }

  @HostListener('keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) { // 13 eq Enter buttonconst
      const element = event.target as HTMLAnchorElement;
      const editItemHTMLElement = document.getElementById('editItem');
      if (this.editItem && ((element === editItemHTMLElement) || (element.parentElement === editItemHTMLElement))) {
        this.setItemPrice(this.editItem);
        this.editItem = undefined;
      }
    }
  }

  @HostListener('window:click', ['$event.target'])
  onClick(element: HTMLAnchorElement) {
    const editItemHTMLElement = document.getElementById('editItem');
    if (this.editItem && (element !== editItemHTMLElement) && (element.parentElement !== editItemHTMLElement)) {
      this.setItemPrice(this.editItem);
      this.editItem = undefined;
    }
  }
}
