import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';

import * as clone from 'clone';

import * as ApiModels from '../../../api';
import { AreaService, EquipmentsForWorkplaceTypeService, WorkplaceTypeService } from '../../../api';

declare const $: any;

@Component({
  selector: 'app-area-upsert',
  templateUrl: './area-upsert.component.html',
  styleUrls: ['./area-upsert.component.scss']
})
export class AreaUpsertComponent implements OnInit {
  @Input() area: ApiModels.Area & ApiModels.AreaWithWorkplacesAndTariffs;
  @Input() equipments: ApiModels.Equipment[];
  @Input() mode: 'add' | 'edit';
  @Output() saved: EventEmitter<void>;

  organisationId: string;
  workCenterId: string;
  newAreaId: string;
  areaPhotos: ApiModels.PhotoInfo[];

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  workplaceTypes$: Observable<ApiModels.WorkplaceType[]>;

  gotoUrl: string;

  get equipmentsControls(): FormArray {
    return this.formFields.get('equipments') as FormArray;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private areaService: AreaService,
    private equipmentsForWorkplaceTypeService: EquipmentsForWorkplaceTypeService,
    private workplaceTypeService: WorkplaceTypeService
  ) {
    this.mode = 'add';
    this.saved = new EventEmitter<void>();
  }

  ngOnInit() {
    if (this.mode === 'add') {
      this.organisationId = this.activatedRoute.snapshot.parent.parent.paramMap.get('id');
      this.workCenterId = this.activatedRoute.snapshot.parent.paramMap.get('id');
    }
    if (this.mode === 'edit') {
      this.workCenterId = this.area.centerId;
    }

    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      workplaceTypeId: new FormControl(this.area ? this.area.workplaceTypeId : null, [Validators.required]),
      aboutText: new FormControl(this.area ? this.area.aboutText : null, [Validators.required]),
      timeForService: new FormControl(this.area ? this.area.timeForService : null),
      equipments: this.formBuilder.array(this.equipments ? this.equipments : [])
    });

    this.workplaceTypes$ = this.workplaceTypeService.get();

    setTimeout(() => {
      $('.ui.checkbox').checkbox();
      $('#workplaceTypeId').dropdown({
        onChange: (value, text, $choice) => {
          this.equipmentsForWorkplaceTypeService.getEquipments([value]).toPromise().then(response => {
            this.clearEquipmentsControls();
            if (response && response.length) {
              this.equipments = response[0].equipments;
              this.equipments.forEach(equipment => {
                this.addEquipmentControl(equipment.id);
              });
            }
          });
        }
      });
      $('.ui.modal.modal_workplace-adding').modal({
        onHidden: () => {
          this.router.navigateByUrl(
            this.gotoUrl ? this.gotoUrl : `/profile/organisation/${this.organisationId}/workcenter/${this.workCenterId}`
          );
        }
      });
    }, 0);
  }

  newEquipmentControl(id?: string): FormGroup {
    return this.formBuilder.group({
      include: false,
      id,
      description: '',
    });
  }

  addEquipmentControl(id: string) {
    this.equipmentsControls.push(this.newEquipmentControl(id));
  }

  removeEquipmentControl(i: number) {
    this.equipmentsControls.removeAt(i);
  }

  clearEquipmentsControls() {
    while (this.equipmentsControls.length !== 0) {
      this.equipmentsControls.removeAt(0);
    }
  }

  onLoadPhoto(e: any) {

  }

  onDeletePhoto(photo: ApiModels.PhotoInfo) {

  }

  isChecked(equipmentId: string) {

  }

  onSave() {
    // {
    //   "centerId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   "aboutText": "string",
    //   "status": 0,
    //   "iconId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   "rulesDocumentId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   "isCoworking": true,
    //   "timeShift": 0,
    //   "timeForService": 0,
    //   "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   "workplaceTypeId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    // }

    const formFields = clone(this.formFields.value);
    formFields.equipments = formFields.equipments.filter(equipment => equipment.include).map(equipment => {
      delete equipment.include;
      return equipment;
    });

    const newArea: ApiModels.AreaAdd = {
      centerId: this.workCenterId,
      ...formFields
    };

    if (this.mode === 'add') {
      this.areaService.createArea(newArea).toPromise().then(response => {
        this.newAreaId = response.id;
        this.onShowWorkplaceAddingModal();
      });
    }

    if (this.mode === 'edit') {
      const editArea: ApiModels.AreaEdit = {
        id: this.area.id,
        ...newArea
      };

      this.areaService.patchArea(editArea).toPromise().then(response => {
        this.saved.emit();
        $('.ui.modal.modal_workplace-adding').modal('show');
      });
    }
  }

  onShowWorkplaceAddingModal() {
    $('.ui.modal.modal_workplace-adding').modal('show');
  }

  onHideWorkplaceAddingModal(url: string) {
    this.gotoUrl = url;
    $('.ui.modal.modal_workplace-adding').modal('hide');
  }

}
