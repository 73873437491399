import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import * as ApiModels from '../../api';
import { TokenService, AccountService } from '../../api';

@Injectable()
export class AuthService {

  token: string;
  refreshToken: string;

  cachedRequests: Array<HttpRequest<any>> = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    private accountService: AccountService
  ) { }

  setToken(token: string) {
    window.localStorage.setItem('token', token);
  }

  getToken() {
    return this.token ? this.token : window.localStorage.getItem('token');
  }

  setRefreshToken(refreshToken: string) {
    window.localStorage.setItem('refreshToken', refreshToken);
  }

  getRefreshToken() {
    return this.refreshToken ? this.refreshToken : window.localStorage.getItem('refreshToken');
  }

  removeAllTokens() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('refreshToken');
    this.token = undefined;
    this.refreshToken = undefined;
  }

  getNewToken() {
    const token = this.getToken();
    const refreshToken = this.getRefreshToken();
    return this.tokenService.refresh({
      token,
      refreshToken
    }).toPromise().then((res: any) => {
      this.token = res.token;
      if (this.token) {
        this.setToken(this.token);
      }
    });
  }

  isLoggedIn() {
    const token = this.getToken();
    return token !== null && token !== undefined && token !== '';
  }

  registration(data: any) {
    // return this.accountService.createUser({
    //   // TODO
    // }).toPromise().then((res: any) => {
    //   this.token = res.token;
    //   if (this.token) {
    //     this.setToken(this.token);
    //   }

    //   this.refreshToken = res.refreshToken;
    //   if (this.refreshToken) {
    //     this.setRefreshToken(this.refreshToken);
    //   }
    // });
  }

  login(username: string, password: string) {
    return this.tokenService.getToken({
      username,
      password
    }).toPromise().then(response => {
      this.token = response.token;
      if (this.token) {
        this.setToken(this.token);
      }

      this.refreshToken = response.refreshToken;
      if (this.refreshToken) {
        this.setRefreshToken(this.refreshToken);
      }
    });
  }

  logout() {
    this.removeAllTokens();

    // if (!this.router) {
    //   window.location.href = '/login'; // #TODO use 'location.href' because the 'router' does not always exist
    //   return;
    // }

    // let queryParamsHandling: 'merge' | 'preserve' | ''; // #TODO The QueryParamsHandling type is not exported from @angular/router
    // queryParamsHandling  = 'merge';

    // const returnUrl = this.router.url;
    // if (returnUrl.indexOf('returnUrl') !== -1) {
    //   queryParamsHandling = 'preserve';
    // }

    // const navigationExtras: NavigationExtras = {
    //   queryParams: { returnUrl },
    //   queryParamsHandling,
    //   preserveFragment: true
    // };
    // this.router.navigate(['/login'], navigationExtras);
  }

  collectFailedRequest(request: any) {
    this.cachedRequests.push(request);
  }

  retryFailedRequests() {
    // retry the requests. this method can
    // be called after the token is refreshed
  }
}
