import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';

import * as ApiModels from '../../api';

import { WorkplaceType } from '../shared/workplace-types-select';

declare const $: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  filters: ApiModels.SearchParamsForWC;
  filtersForMap: ApiModels.SearchParamsForWC;
  tempFilters: ApiModels.SearchParamsForWC;
  selectedFilters: ApiModels.SearchParamsForWC;
  searchResults: ApiModels.WorkCenterWithAreas[];
  pinResult: ApiModels.WorkCenterWithAreas;

  selectedWorkplaceTypes: WorkplaceType[];
  selectedWorkplaceTypeIds: string[];
  date: Date;
  times: number[] = [];
  timeFrom: number;
  timeTo: number;
  name: string;
  tariffType: number;
  city: string;
  radiusStart: number;
  radiusEnd: number;
  ratingStart: number;
  ratingEnd: number;

  location: string;

  searchMapResultsVisible: boolean;
  searchPopupVisible: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.searchPopupVisible = false;
    this.tempFilters = {
      workplaceType: [],
      date: null,
      timeFrom: null,
      timeTo: null
    };
    this.selectedFilters = { ...this.tempFilters };
    this.filtersForMap = { ...this.tempFilters };
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);

      if (params.workplaceTypeIds instanceof Array) {
        this.selectedWorkplaceTypeIds = params.workplaceTypeIds;
      } else if (typeof(params.workplaceTypeIds) === 'string') {
        this.selectedWorkplaceTypeIds = [params.workplaceTypeIds];
      } else {
        this.selectedWorkplaceTypeIds = [];
      }
      this.selectedWorkplaceTypes = this.selectedWorkplaceTypeIds.map(id => {
        return { id };
      });

      this.date = params.date ? new Date(params.date) : null;
      this.timeFrom = params.timeFrom ? +params.timeFrom : null;
      this.timeTo = params.timeTo ? +params.timeTo : null;

      this.tariffType = params.tariffType;
      this.name = params.name;
      this.city = params.city || this.location;
      this.radiusStart = params.radiusStart;
      this.radiusEnd = params.radiusEnd;
      this.ratingStart = params.ratingStart;
      this.ratingEnd = params.ratingEnd;

      this.buildSelectedFilter();
    });

    this.searchMapResultsVisible = true;
  }

  changeWorkplaceTypes(workplaceTypes: WorkplaceType[]) {
    this.selectedWorkplaceTypes = workplaceTypes;
    this.selectedWorkplaceTypeIds = workplaceTypes.map(workplaceType => workplaceType.id);
    this.buildTempFilter();
  }

  changeDate(newDate: Date) {
    this.date = newDate;
    this.buildTempFilter();
  }

  changeTimes(newTime: number[]) {
    this.timeFrom = newTime[0];
    this.timeTo = newTime[1];
    this.buildTempFilter();
  }

  changeFilters(newFilters: ApiModels.SearchParamsForWC) {
    this.filters = newFilters;
    this.buildSelectedFilter();
  }

  changeTempFilters(newFilters: ApiModels.SearchParamsForWC) {
    this.tempFilters = newFilters;
  }

  changeFiltersForMap(newFilters: ApiModels.SearchParamsForWC) {
    this.filtersForMap = { ...newFilters };
    this.filters = { ...newFilters };
  }

  showSearchMapAction() {
    this.searchPopupVisible = true;
    setTimeout(() => {
      this.filtersForMap = this.filters;
      $('.ui.modal.search-map-modal').modal('show');
    }, 0);
  }

  hideSearchMapAction() {
    $('.ui.modal.search-map-modal').modal('hide');
    this.searchPopupVisible = false;
  }

  hideSearchMapFilters() {
    this.searchMapResultsVisible = !this.searchMapResultsVisible;
  }

  buildSelectedFilter() {
    this.buildTempFilter();
    this.selectedFilters = { ...this.tempFilters };
    this.filtersForMap = { ...this.tempFilters };
  }

  getWorkplaceTypesForTempFilter() {
    let workplaceTypes: ApiModels.WPType[];
    if (this.tempFilters && this.tempFilters.workplaceType && this.selectedWorkplaceTypeIds) {
      workplaceTypes = this.selectedWorkplaceTypeIds.map(id => {
        const item = this.tempFilters.workplaceType.find(workplaceType => workplaceType.id === id);
        if (item) {
          return item;
        } else {
          return { id };
        }
      });
    } else if (this.selectedWorkplaceTypeIds) {
      workplaceTypes = this.selectedWorkplaceTypeIds.map(id => {
        return { id };
      });
    } else {
      workplaceTypes = [];
    }

    return workplaceTypes;
  }

  // TODO refactoring (возможно лучше это делать в search-result и search-result-for-map)
  goToWorkCenterSearch(workCenterId, emittedFromMapModal: boolean = false) {
    const filters = emittedFromMapModal ? this.selectedFilters : this.filtersForMap;

    if (emittedFromMapModal) {
      this.hideSearchMapAction();
    }

    const queryParams = {
      workplaceTypes: this.selectedFilters.workplaceType ? JSON.stringify(this.selectedFilters.workplaceType) : undefined,
      date: filters.date ? formatDate(filters.date, 'MM-dd-yyyy', 'ru') : undefined,
      timeFrom: filters.timeFrom ? filters.timeFrom : undefined,
      timeTo: filters.timeTo ? filters.timeTo : undefined,
      tariffType: filters.tariffType ? 1 : 0
    };

    this.router.navigate([ '/workcenter-search', workCenterId ], { queryParams });
  }

  private buildTempFilter() {
    this.tempFilters = {
      workplaceType: this.getWorkplaceTypesForTempFilter(),
      date: this.date || undefined,
      timeFrom: this.timeFrom || undefined,
      timeTo: this.timeTo || undefined,
      ...this.filters
    };
  }

  resultsChanged(searchResults) {
    this.searchResults = searchResults;
  }

  onPinClicked(pinResult) {
    this.pinResult = pinResult;
  }
}
