import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

import * as clone from 'clone';

import * as ApiModels from '../../../api';
import { AreaService, RentService, WorkCenterService } from '../../../api';

import { ToggleOptions } from '../toggle';

import { SelectedItem } from './reservation-picker-board/reservation-picker-board.model';
import { RentAdd, ReservationPickerOptions } from './reservation-picker.model';

declare const $: any;

@Component({
  selector: 'app-reservation-picker',
  templateUrl: './reservation-picker.component.html',
  styleUrls: ['./reservation-picker.component.scss']
})
export class ReservationPickerComponent implements OnInit, OnChanges {
  @Input() options: ReservationPickerOptions;
  @Output() changeValue: EventEmitter<any>;

  areaId: string;
  tariffType: number;
  date: Date;
  workplaceTypeName: string;
  schedules: ApiModels.ScheduleInfo[];
  tariffs: ApiModels.TariffInfo[];
  workplaces: ApiModels.WorkplaceInfoWithRents[];
  area: ApiModels.AreaWithEqupmentsTariffsWorkplacesAndRents;

  value: ApiModels.RentAdd[];
  displayValue: string;

  tariffTypeToggleOptions: ToggleOptions;

  constructor(
    private router: Router,
    private areaService: AreaService,
    private rentService: RentService,
    private workCenterService: WorkCenterService
  ) {
    this.changeValue = new EventEmitter<any>();
  }

  ngOnInit() {
    this.tariffTypeToggleOptions = {
      id: 'tariffType',
      labels: {
        left: {
          text: 'По часам',
          value: 0
        },
        right: {
          text: 'По дням',
          value: 1
        },
        style: {
          fontSize: '18px',
          lineHeight: '22px'
        }
      }
    };
  }

  ngOnChanges(changes: any) {
    if (changes.options) {
      if (this.options) {
        this.areaId = this.options.areaId;
        this.date = this.options.date;
        this.tariffType = this.options.tariffType;

        this.getData();
      }
    }
  }

  onChangeTariffType(value: number) {
    this.tariffType = value;
    this.getData();
  }

  onChangeDate(value: Date) {
    this.date = value;
    this.getData();
  }

  onClearValue() {
    this.value = undefined;
    this.displayValue = undefined;
  }

  onChangeSelectedItems(selectedItems: SelectedItem[]) {
    this.value = [];
    const groups: RentAdd[] = [];
    const newRents = [];

    if (this.tariffType) {
      selectedItems
      .sort((prev, next) => (prev.headerValue as Date).getTime() - (next.headerValue as Date).getTime())
      .forEach(item => {
        const dateEnd: Date = clone(item.headerValue);
        dateEnd.setDate(dateEnd.getDate() + 1);

        const newGroup = {
          workplaceId: item.workplaceId,
          workplaceNumber: item.workplaceNumber,
          headerValues: [item.headerValue],
          cost: item.price,
          dateStart: item.headerValue as Date,
          dateEnd,
          tariffType: this.tariffType
        };

        groups.push(newGroup);
      });
    } else {
      selectedItems
        .sort((prev, next) => (prev.headerValue as number) - (next.headerValue as number))
        .forEach(item => {
          const existingGroup = groups.find(group => {
            return group.workplaceId === item.workplaceId &&
                   group.headerValues[group.headerValues.length - 1] === (item.headerValue as number) - 1;
          });

          if (existingGroup) {
            existingGroup.headerValues.push(item.headerValue);
            existingGroup.cost = existingGroup.cost + item.price;
            existingGroup.dateEnd = new Date(
              this.date.getFullYear(),
              this.date.getMonth(),
              this.date.getDate(),
              (item.headerValue as number) + 1
            );
          } else {
            const newGroup = {
              workplaceId: item.workplaceId,
              workplaceNumber: item.workplaceNumber,
              headerValues: [item.headerValue],
              cost: item.price,
              dateStart: new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), (item.headerValue as number)),
              dateEnd: new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), (item.headerValue as number) + 1),
              tariffType: this.tariffType
            };

            groups.push(newGroup);
          }
        });
    }

    this.value = groups.map(group => {
      return {
        workplaceId: group.workplaceId,
        dateStart: group.dateStart,
        dateEnd: group.dateEnd,
        tariffType: group.tariffType
      };
    });

    const displayValue = [];
    groups.forEach(group => {
      if (this.tariffType) {
        const workplace = `Место ${group.workplaceNumber}`;
        const date = formatDate(group.headerValues[0], 'EEE dd.MM.yyyy', 'ru');
        const cost = `${group.cost} ₽`;

        displayValue.push(`${workplace}, ${date} ${cost}`);
      } else {
        const workplace = `Место ${group.workplaceNumber}`;

        const hoursCount = (group.headerValues[group.headerValues.length - 1] as number) - (group.headerValues[0] as number) + 1;
        const hours = `${hoursCount} ${
          (hoursCount % 20 === 1) ? ' час' : ((hoursCount % 20 === 0 || hoursCount % 20 < 5) ? ' часа' : ' часов')
        }`;

        const hoursPeriod = `${group.headerValues[0]}:00 - ${(group.headerValues[group.headerValues.length - 1] as number) + 1}:00`;

        const cost = `${group.cost} ₽`;

        displayValue.push(`${workplace}, ${hours} ${hoursPeriod} ${cost}`);
      }
    });
    this.displayValue = displayValue.join('; ');
  }

  onRentsCreate() {
    if (!this.value || !this.value.length) {
      this.onClose();
      return;
    }

    this.rentService.rentCreate(this.value).toPromise().then(response => {
      this.onClose();

      const queryParams = {
        ids: response
      };
      this.router.navigate(['/reservation/confirmation'], { queryParams });
    });
  }

  onClose() {
    $('.ui.modal.modal_reservation-picker').modal('hide');
  }

  private getData() {
    if (!this.areaId) {
      return;
    }

    this.areaService.getAreaByIdExtended(this.areaId, this.date, this.tariffType).toPromise().then(response => {
      this.workplaceTypeName = response.workplaceTypeName;
      this.schedules = response.schedules;
      this.tariffs = response.tariffs;
      this.workplaces = response.workplaces;
      this.area = {
        id: this.areaId,
        workplaceTypeName: response.workplaceTypeName,
        tariffs: response.tariffs,
        workplaces: response.workplaces
      };
    });
  }

}
