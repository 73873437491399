import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import * as ApiModels from '../../../../api';
import { AccountService } from '../../../../api';

@Component({
  selector: 'app-registration-code',
  templateUrl: './registration-code.component.html',
  styleUrls: ['./registration-code.component.scss']
})
export class RegistrationCodeComponent implements OnInit {
  @Input() phoneNumber: string;
  @Output() back: EventEmitter<string>;
  @Output() secretCodeSet: EventEmitter<string>;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  constructor(
    private accountService: AccountService
  ) {
    this.back = new EventEmitter();
    this.secretCodeSet = new EventEmitter();
  }

  ngOnInit() {
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      number1: new FormControl(null),
      number2: new FormControl(null),
      number3: new FormControl(null),
      number4: new FormControl(null)
    });
  }

  onBackButtonClick() {
    this.back.emit();
  }

  onVerifyButtonClick() {
    let code = '';
    code += this.formFields.get('number1').value.toString();
    code += this.formFields.get('number2').value.toString();
    code += this.formFields.get('number3').value.toString();
    code += this.formFields.get('number4').value.toString();
    this.accountService.postVerifyCodeToRegisterUser({
      phoneNumber: this.phoneNumber,
      code: +code
    }).toPromise().then(response => {
      this.secretCodeSet.emit(response.secretCode);
    });
  }
}
