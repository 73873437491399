import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import * as ApiModels from '../../../../api';
import { WorkCenterService } from '../../../../api';

import { WorkCenterWithAreas } from '../search-result.model';

@Component({
  selector: 'app-search-result-for-map',
  templateUrl: './search-result-for-map.component.html',
  styleUrls: ['./search-result-for-map.component.scss']
})
export class SearchResultForMapComponent implements OnInit, OnChanges {
  @Input() filters: ApiModels.SearchParamsForWC;
  @Input() pinResult: WorkCenterWithAreas;
  @Output() resultsChanged: EventEmitter<WorkCenterWithAreas[]>;
  @Output() seeAvailableAreasClicked: EventEmitter<string>;

  results: WorkCenterWithAreas[];
  objectsCount: number;

  constructor(private workCenterService: WorkCenterService) {
    this.seeAvailableAreasClicked = new EventEmitter();
    this.resultsChanged = new EventEmitter();
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters) {
      console.log('ngOnChanges', changes.filters);

      if (this.filters) {
        this.searchStart();
      }
    }
    if (changes.pinResult) {
      if (this.pinResult) {
        this.results = [this.pinResult];
      }
    }
  }

  onSeeAvailableAreasClicked(workCenterId) {
    this.seeAvailableAreasClicked.emit(workCenterId);
  }

  private searchStart() {
    this.workCenterService.searchWithBodyParams(this.filters).subscribe(response => {
      this.results = response;
      this.objectsCount = this.results.length;
      this.resultsChanged.emit(this.results);
    });
  }
}
