import { Component, OnInit, Input } from '@angular/core';

import * as ApiModels from '../../../api';

@Component({
  selector: 'app-area-card',
  templateUrl: './area-card.component.html',
  styleUrls: ['./area-card.component.scss']
})
export class AreaCardComponent implements OnInit {
  @Input() workplaceTypeVisible: boolean;
  @Input() area: ApiModels.Area & ApiModels.AreaWithWorkplacesAndTariffs;
  @Input() equipments: ApiModels.Equipment[];
  @Input() photoIds: string[];

  constructor() { }

  ngOnInit() {
  }

}
