import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isArray } from 'util';

import * as ApiModels from '../../../api';
import { CityService, WorkCenterService, OrganisationService, PhotoService } from '../../../api';

import { Utils } from '../../shared/utils';

import { ScheduleWeekDay, ScheduleGrouped, ScheduleGroupedNumber } from '../workcenter.model';

declare const $: any;

@Component({
  selector: 'app-workcenter-upsert',
  templateUrl: './workcenter-upsert.component.html',
  styleUrls: ['./workcenter-upsert.component.scss']
})
export class WorkCenterUpsertComponent implements OnInit, AfterViewInit {
  @Input() mode: 'add' | 'edit';
  @Input() workCenter: ApiModels.WorkCenterAdd;
  @Input() workCenterPhotos: ApiModels.PhotoInfo[];
  @Output() saved: EventEmitter<void>;

  cities: ApiModels.City[];
  photosToUpload: (string | ArrayBuffer)[];

  weekDays: ScheduleWeekDay[];
  tempWeekDays: ScheduleWeekDay[];
  schedulesValues: ScheduleGroupedNumber[];

  workCenterForm: FormGroup;
  workCenterFormSubmitted: boolean;

  organisationId: string;
  workCenterId: string;

  get phonesControls(): FormArray {
    return this.workCenterForm.get('phones') as FormArray;
  }

  // get schedulesControls(): FormArray {
  //   return this.workCenterForm.get('schedules') as FormArray;
  // }

  get additionsControls(): FormArray {
    return this.workCenterForm.get('additions') as FormArray;
  }

  constructor(
    private router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private cityService: CityService,
    private workCenterService: WorkCenterService,
    private organisationService: OrganisationService,
    private photoService: PhotoService,
    private http: HttpClient
  ) {
    this.mode = 'add';
    this.saved = new EventEmitter();
    this.workCenterPhotos = [];
    this.photosToUpload = [];
    // we start from default value="" so first index in this array - 1
    this.weekDays = [
      { number: 1, uiValue: `1: 'Пн'`, value: 'Пн', disabled: false },
      { number: 2, uiValue: `2: 'Вт'`, value: 'Вт', disabled: false },
      { number: 3, uiValue: `3: 'Ср'`, value: 'Ср', disabled: false },
      { number: 4, uiValue: `4: 'Чт'`, value: 'Чт', disabled: false },
      { number: 5, uiValue: `5: 'Пт'`, value: 'Пт', disabled: false },
      { number: 6, uiValue: `6: 'Сб'`, value: 'Сб', disabled: false },
      { number: 7, uiValue: `7: 'Вс'`, value: 'Вс', disabled: false }
    ];

    this.tempWeekDays = this.weekDays;
    this.schedulesValues = [];
    this.workCenterFormSubmitted = false;
  }

  ngOnInit() {
    this.cityService.get().toPromise().then(cities => {
      this.cities = cities;
    });

    this.initForm();

    this.organisationId = this.mode === 'edit' ? this.workCenter.organisationId : this.route.snapshot.parent.paramMap.get('id');
    this.workCenterId = this.mode === 'edit' ? this.workCenter.id : undefined;

    // this.workCenterService.getWorkCenterDataById('99000001-0000-0000-0000-000000000001').toPromise().then(response => {
    //   console.log(response.schedules);
    //   this.schedulesValues = Utils.getSchedulesGroupedNumber(response.schedules);
    //   console.log(this.schedulesValues);
    //   this.tempWeekDays = this.getTempWeekDays();

    //   setTimeout(() => {
    //     this.schedulesValues.forEach((scheduleValue, index) => {
    //       $(`.ui.dropdown.schedule-field__week-days_${index}`).dropdown('set selected', scheduleValue.daysOfWeek);
    //     });
    //   }, 0);
    // });

    if (this.mode === 'edit') {
      this.workCenterService.getWorkCenterDataById(this.workCenterId).toPromise().then(workCenterResponse => {
        this.workCenter = workCenterResponse;
        this.workCenterForm.patchValue(this.workCenter);

        this.photoService.getPhotosForGUID(this.workCenterId).toPromise().then((photos) => {
          this.workCenterPhotos = photos;
        });

        this.phonesControls.clear();
        this.workCenter.phones.forEach((contact) => {
          this.phonesControls.push(this.newControlValue(contact.phoneNumber));
        });

        this.schedulesValues = Utils.getSchedulesGroupedNumber(this.workCenter.schedules);
        this.tempWeekDays = this.getTempWeekDays();

        setTimeout(() => {
          this.schedulesValues.forEach((scheduleValue, index) => {
            $(`.ui.dropdown.schedule-field__week-days_${index}`).dropdown('set selected', scheduleValue.daysOfWeek);
          });
        }, 0);
      });
    }
  }

  ngAfterViewInit() {
    this.initUIControls();
  }

  // newScheduleControl(schedule?: ScheduleGroupedNumber): FormGroup {
  //   const scheduleControl = this.formBuilder.group({
  //     daysOfWeek: schedule ? schedule.daysOfWeek : [],
  //     times:  schedule ? schedule.times : []
  //   });

  //   Object.keys(scheduleControl.controls).forEach((control) => {
  //     scheduleControl.get(control).setValidators(Validators.required);
  //   });

  //   return scheduleControl;
  // }

  // addScheduleControl(schedule?: ScheduleGroupedNumber) {
  //   this.schedulesControls.push(this.newScheduleControl(schedule));
  // }

  // removeScheduleControl(index: number) {
  //   this.schedulesControls.removeAt(index);
  // }

  addSchedule() {
    this.schedulesValues.push({
      daysOfWeek: this.workCenterForm.get('daysOfWeek').value,
      times: this.workCenterForm.get('times').value
    });

    this.workCenterForm.patchValue({
      daysOfWeek: undefined,
      times: undefined
    });

    this.tempWeekDays = this.getTempWeekDays();

    setTimeout(() => {
      $('.ui.dropdown.schedule-field__week-days_add-control').dropdown('clear'); // TODO не работает
      this.schedulesValues.forEach((scheduleValue, index) => {
        $(`.ui.dropdown.schedule-field__week-days_${index}`).dropdown('refresh'); // TODO не работает
        $(`.ui.dropdown.schedule-field__week-days_${index}`).dropdown('set selected', scheduleValue.daysOfWeek);
      });
    }, 0);
  }

  removeSchedule(index: number) {
    this.schedulesValues.splice(index, 1);
    this.tempWeekDays = this.getTempWeekDays();

    setTimeout(() => {
      $(`.ui.dropdown.schedule-field__week-days`).dropdown();
    }, 0);
  }

  setSchedulesValuesTimes(times: number[], index: number) {
    this.schedulesValues[index].times = times;
  }

  setTimes(times: number[]) {
    this.workCenterForm.get('times').patchValue(times);
  }

  isDisabledAddScheduleButton() {
    return !this.workCenterForm.get('daysOfWeek').value ||
      !this.workCenterForm.get('daysOfWeek').value.length ||
      !this.workCenterForm.get('times').value ||
      !this.workCenterForm.get('times').value.length;
  }

  useLegalAddress() {
    this.organisationService.getOrganisationById(this.organisationId).toPromise().then(organisation => {
      this.workCenterForm.patchValue({address: organisation.legalAddress});
    });
  }

  fillLocationByAddress() {
    const geocoderApiKey = '1ac21c60-80b1-4293-95c1-123d7aa5677e';
    // TODO не учитывается город
    const address = this.workCenterForm.get('address').value;
    this.http.get(
      `https://geocode-maps.yandex.ru/1.x/?apikey=${geocoderApiKey}&format=json&results=1&geocode=${address}`,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    ).toPromise()
      .then((geocoderResponse: any) => {
        const pos = geocoderResponse.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos;
        const latitudeValue = pos.split(' ')[0];
        const longitudeValue = pos.split(' ')[1];
        this.workCenterForm.patchValue({latitude: latitudeValue});
        this.workCenterForm.patchValue({longitude: longitudeValue});
      });
  }

  onSubmitFormHandler() {
    if (this.workCenterForm.invalid) {
      return;
    }

    const schedulesArray: ApiModels.ScheduleInfo[] = [];
    this.schedulesValues.forEach(scheduleValue => {
      scheduleValue.daysOfWeek.forEach(dayOfWeek => {
        schedulesArray.push({
          dayOfWeek,
          startHour: `${scheduleValue.times[0]}:00:00`,
          endHour: `${scheduleValue.times[1]}:00:00`
        });
      });
    });
    const daysOfWeek = this.workCenterForm.get('daysOfWeek').value as number[];
    const times = this.workCenterForm.get('times').value as number[];
    if (
      daysOfWeek &&
      daysOfWeek.length &&
      times &&
      times.length
    ) {
      daysOfWeek.forEach(dayOfWeek => {
        schedulesArray.push({
          dayOfWeek,
          startHour: `${times[0]}:00:00`,
          endHour: `${times[1]}:00:00`
        });
      });
    }

    const phonesArray: ApiModels.PhonesInfo[] = [];
    (this.workCenterForm.get('phones').value as string[]).forEach(phoneNumber => {
      phonesArray.push({phoneNumber, phoneName: ''});
    });

    if (this.mode === 'edit') {
      const updateData: ApiModels.WorkCenterUpdate = {
        id: this.workCenterId,
        name: this.workCenterForm.get('name').value,
        cityId: this.workCenterForm.get('cityId').value,
        address: this.workCenterForm.get('address').value,
        longitude: +this.workCenterForm.get('longitude').value,
        latitude: +this.workCenterForm.get('latitude').value,
        routeMetro: this.workCenterForm.get('routeMetro').value,
        routeTime: this.workCenterForm.get('routeTime').value,
        routeDetails: this.workCenterForm.get('routeDetails').value,
        aboutText: this.workCenterForm.get('aboutText').value,
        hasParking: this.workCenterForm.get('hasParking').value,
        hasCardPayment: this.workCenterForm.get('hasCardPayment').value,
        phones: phonesArray,
        schedules: schedulesArray
      };
      this.workCenterService.workCenterUpdate(updateData).toPromise().then(wcResponse => {
        if (this.photosToUpload && this.photosToUpload.length) {
          this.photoService.postUploadFiles(this.photosToUpload).toPromise().then(photoResponse => {
            this.markAsSubmitted();
          });
        } else {
          this.markAsSubmitted();
        }
      });
    } else {
      const newData: ApiModels.WorkCenterAdd = {
        name: this.workCenterForm.get('name').value,
        cityId: this.workCenterForm.get('cityId').value,
        address: this.workCenterForm.get('address').value,
        longitude: +this.workCenterForm.get('longitude').value,
        latitude: +this.workCenterForm.get('latitude').value,
        routeMetro: this.workCenterForm.get('routeMetro').value,
        routeTime: this.workCenterForm.get('routeTime').value,
        routeDetails: this.workCenterForm.get('routeDetails').value,
        aboutText: this.workCenterForm.get('aboutText').value,
        hasParking: this.workCenterForm.get('hasParking').value,
        hasCardPayment: this.workCenterForm.get('hasCardPayment').value,
        phones: phonesArray,
        schedules: schedulesArray,
        organisationId: this.organisationId
      };
      this.workCenterService.create(newData).toPromise().then(wcResponse => {
        if (this.photosToUpload && this.photosToUpload.length) {
          this.photoService.postUploadFiles(this.photosToUpload).toPromise().then(photoResponse => {
            this.router.navigateByUrl(`/profile/organisation/${this.organisationId}/workcenter/${wcResponse.id}`);
            // this.markAsSubmitted();
          });
        } else {
          this.router.navigateByUrl(`/profile/organisation/${this.organisationId}/workcenter/${wcResponse.id}`);
        }
      });
    }
  }

  markAsSubmitted() {
    this.workCenterFormSubmitted = true;
    this.saved.emit();
  }

  isFormControlHasError(controlName: string) {
    const control = this.workCenterForm.get(controlName);
    if (!control) {
      return;
    }

    return this.workCenterForm.get(controlName).errors && this.workCenterFormSubmitted ||
           this.workCenterForm.get(controlName).errors && (this.workCenterForm.get(controlName).dirty ||
           this.workCenterForm.get(controlName).touched);
  }

  isFormArrayHasError(formArrayName: string) {
    const formArrayControls = this.workCenterForm.get(formArrayName)['controls'];
    if (!formArrayControls) {
      return;
    }

    let hasError = false;
    const controlsCount = formArrayControls.length;
    for (let i = 0; i < controlsCount; i++) {
      if (formArrayControls[i].controls) {
        hasError = this.isFormGroupHasError(formArrayControls[i].controls);
        if (hasError) {
          break;
        }
      } else if (formArrayControls[i].errors) {
        hasError = true;
        break;
      }
    }

    return hasError && this.workCenterFormSubmitted;
  }

  isFormGroupHasError(formArrayControls: any) {
    let hasError = false;

    Object.keys(formArrayControls).forEach((control) => {
      if (formArrayControls[control].errors) {
        hasError = true;
      }
    });

    return hasError;
  }

  isNoPhotoLoaded() {
    if (this.workCenterFormSubmitted && !this.workCenterPhotos.length) {
      return true;
    }
    return false;
  }

  newControlValue(entityName?, isRequired: boolean = false): FormControl {
    const control = this.formBuilder.control(entityName ? entityName : '');
    if (isRequired) {
      control.setValidators([Validators.required]);
    }
    return control;
  }

  newPhoto(url: any): ApiModels.PhotoInfo {
    return {
      id: null,
      fileName: url
    };
  }

  addPhoneControl() {
    if (this.isLastEntityValueNotNull('phonesControls'))  {
      this.phonesControls.push( this.newControlValue(null, true) );
    }
  }

  addPhoto(file: Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.workCenterPhotos.push( this.newPhoto(reader.result) );
      this.photosToUpload.push( reader.result );
    };
  }

  removeFormItem(entityName, index: number) {
    this[entityName].removeAt(index);
  }

  removePhoto(photo: ApiModels.PhotoInfo) {
    if (this.workCenterPhotos.length > 1) {
      let fieldToCompare = 'id';
      if (!photo.id) {
        fieldToCompare = 'fileName';
      }
      this.workCenterPhotos = this.workCenterPhotos.filter(workCenterPhoto => workCenterPhoto[fieldToCompare] !== photo[fieldToCompare] );
    }
    if (this.photosToUpload.length) {
      this.photosToUpload = this.photosToUpload.filter(photoToUpload => photoToUpload !== photo.fileName);
    }
  }

  private initForm() {
    this.workCenterForm = this.formBuilder.group({
      name: ['', Validators.required],
      cityId: ['', Validators.required],
      address: ['', Validators.required],
      longitude: ['', Validators.required],
      latitude: ['', Validators.required],
      routeMetro: [''],
      routeTime: [''],
      routeDetails: [''],
      // schedules: this.formBuilder.array([
      //   this.newScheduleControl()
      // ]),
      daysOfWeek: [],
      times: [],
      aboutText: ['', Validators.required],
      hasParking: [false],
      hasCardPayment: [false],
      rules: ['default', Validators.required],
      phones: this.formBuilder.array([
        this.newControlValue(null, true)
      ]),
    });
  }

  private getTempWeekDays() {
    const tempWeekDays = this.weekDays.filter(weekDay => {
      const findIndex = this.schedulesValues.findIndex((item: ScheduleGroupedNumber) => {
        if (item.daysOfWeek) {
          return item.daysOfWeek.includes(weekDay.number);
        }
        return false;
      });
      return findIndex === -1;
    });

    return tempWeekDays;
  }

  // // Method to construct schedules array according to api model structure
  // private constructSchedulesFromForm() {
  //   const schedules = [];

  //   if (this.schedulesControls.value) {
  //     this.schedulesControls.value.forEach((scheduleRow: ScheduleGroupedNumber) => {
  //       scheduleRow.daysOfWeek.forEach((weekDay) => {
  //         const scheduleWeekDay = this.weekDays.find(scheduledayOfWeek => scheduledayOfWeek.number === weekDay);

  //         schedules.push({
  //           dayOfWeek: scheduleWeekDay ? scheduleWeekDay.number : null,
  //           startHour: scheduleRow.times[0],
  //           endHour: scheduleRow.times[1]
  //         });
  //       });
  //     });
  //   }
  // }

  private initUIControls() {
    $('.ui.dropdown').dropdown();
    $('.ui.checkbox').checkbox();
  }

  isLastEntityValueNotNull(entityName) {
    let isNotNull = true;

    const formEntityControls = this[entityName].controls;
    if (!formEntityControls) {
      return;
    }

    if (!formEntityControls.length) {
      return;
    }
    const lastValue = formEntityControls[formEntityControls.length - 1].value;
    // FormGroup
    if (typeof lastValue === 'object') {
      Object.keys(lastValue).forEach((control) => {
        if (isArray(lastValue[control]) && !lastValue[control].length || !lastValue[control]) {
          isNotNull = false;
        }
      });
    }

    if ( isArray(lastValue) && !lastValue.length || !lastValue) {
      isNotNull = false;
    }

    return isNotNull;
  }

  // private disableDropdowns() {
  //   const dropdowns = $(`.ui.dropdown.schedule-field__week-days`);

  //   if (dropdowns.length === 1 && this.mode !== 'edit') {
  //     return;
  //   }

  //   for (let i = 0; i < dropdowns.length - 1; i++) {
  //     $(dropdowns[i]).addClass('disabled');
  //   }
  // }
}
