import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import * as ApiModels from '../../../../api';
import { UserService } from '../../../../api';

import { ToggleOptions } from '../../../shared/toggle';

import { UserView } from '../../profile-user.model';

@Component({
  selector: 'app-profile-user-info-edit',
  templateUrl: './profile-user-info-edit.component.html',
  styleUrls: ['./profile-user-info-edit.component.scss']
})
export class ProfileUserInfoEditComponent implements OnInit {
  @Input() user: UserView;
  @Output() saved: EventEmitter<void>;

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  sexToggleOptions: ToggleOptions;

  constructor(
    private userService: UserService
  ) {
    this.saved = new EventEmitter<void>();
  }

  ngOnInit() {
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      firstName: new FormControl(
        this.user ? this.user.firstName : null,
        [Validators.required]
      ),
      lastName: new FormControl(
        this.user ? this.user.lastName : null,
        [Validators.required]
      ),
      sex: new FormControl(this.user ? this.user.sex : null),
      phoneNumber: new FormControl(
        this.user ? this.user.phoneNumber : null,
        [Validators.required]
      ),
      email: new FormControl(this.user ? this.user.email : null, [Validators.email])
    });

    this.sexToggleOptions = {
      labels: {
        left: {
          text: 'М',
          value: 'M'
        },
        right: {
          text: 'Ж',
          value: 'F'
        }
      }
    };
  }

  onUploadImage() {
    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.click();
  }

  fileInputChange(e: Event) {
    const fileList = (e.target as any).files as FileList;

    if (fileList.length) {
      const file = fileList[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('description', '');
      // TODO
      // this.userService.postUserPhoto(formData).toPromise().then(response => {
      //   console.log(response);
      // });
    }
  }

  onDeleteImage() {
    this.userService.deletePhotoByAttachId().toPromise().then();
  }

  onChangeSexValue(value: UserView['sex']) {
    this.formFields.patchValue({ sex: value });
  }

  onSave() {
    if (this.formFields.invalid) {
      // TODO
      // console.log(this.formFields);
      // this.errors = [];
      // Object.keys(this.formFields.controls).forEach(key => {
      //   if (this.formFields.controls[key].status === 'INVALID') {
      //     let errorText: string;
      //     if (this.formFields.controls[key].errors.required) {
      //       errorText = 'Это поле обязательно для заполнения';
      //     } else if (this.formFields.controls[key].errors.pattern) {
      //       errorText = 'Это поле некорректно заполнено';
      //     } else if (this.formFields.controls[key].errors.email) {
      //       errorText = 'Это поле некорректно заполнено';
      //     } else if (this.formFields.controls[key].errors.minLength) {
      //       errorText = 'Это поле слишком короткое';
      //     }
      //     this.errors.push({ key: errorText });
      //   }
      // });

      return;
    }

    console.log(this.formFields.value);
    this.userService.editProfile(
      this.formFields.value
    ).toPromise().then(response => {
      this.saved.emit();
    });
  }
}
