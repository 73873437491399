import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import * as ApiModels from '../../../api';
import { CityService, EquipmentsForWorkplaceTypeService } from '../../../api';

import { WorkplaceType } from '../workplace-types-select/workplace-types.model';

declare const $: any;

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements AfterViewInit, OnChanges, OnInit {
  @Input() locationSectionVisible: boolean;
  @Input() selectedFilters: ApiModels.SearchParamsForWC;
  @Input() workplaceTypes: WorkplaceType[];
  @Output() сhangeFilters: EventEmitter<ApiModels.SearchParamsForWC>;
  @Output() changeTempFilters: EventEmitter<ApiModels.SearchParamsForWC>;

  cities: ApiModels.CityItem[];

  form: any;
  defaultFilters: ApiModels.SearchParamsForWC;

  constructor(
    private cityService: CityService,
    private equipmentsForWorkplaceTypeService: EquipmentsForWorkplaceTypeService
  ) {
    this.сhangeFilters = new EventEmitter<ApiModels.SearchParamsForWC>();
    this.changeTempFilters = new EventEmitter<ApiModels.SearchParamsForWC>();
  }

  ngOnInit() {
    if (!this.selectedFilters) {
      this.selectedFilters = {};
    }

    $('.ui.fluid.accordion.workplace-type')
      .accordion({
        selector: {
          trigger: '.title'
        }
      });
  }

  ngAfterViewInit() {
    this.form = $('#filter-form');
    this.cityService.get().toPromise().then(cites => {
      this.cities = cites;
      this.defaultFilters = {
        name: '',
        tariffType: 0,
        cityId: this.cities[0].id,
        radius: 0,
        ratingFrom: 1,
        ratingTo: 10
      };
      this.initFormAndControls();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedFilters) {
      // TODO
      // this.updateView();

      if (this.selectedFilters && this.selectedFilters.workplaceType) {

      }
    }

    if (changes.workplaceTypes) {
      if (this.workplaceTypes) {
        const workplaceTypeIds = this.workplaceTypes.map(workplaceType => workplaceType.id);
        this.equipmentsForWorkplaceTypeService.getEquipments(workplaceTypeIds).subscribe(equipmentGroups => {
          this.workplaceTypes.forEach(workplaceType => {
            const findEquipmentGroup = equipmentGroups.find(equipmentGroup => {
              return equipmentGroup.workplaceTypeId === workplaceType.id;
            });
            workplaceType.equipments = findEquipmentGroup ? findEquipmentGroup.equipments : [];
          });

          setTimeout(() => {
            this.updateWorkplaceTypesPriceSliders();

            // TODO
            // this.setEquipmentCheckboxes();
            // this.updateWorkplaceTypeForSelectedFilters();
          }, 0);
        });
      }
    }
  }

  updateView() {
    this.updateSliders();

    // TODO
    // this.updateWorkplaceTypesPriceSliders();
  }

  applyFilters() {
    this.buildFilters();
    this.сhangeFilters.emit(this.selectedFilters);
  }

  updateFormField(fieldName: string, fieldValue: string | number) {
    this.form.form('set value', fieldName, fieldValue);
    this.buildFilters();
  }

  setDefaultFilters() {
    this.form.form('set values', this.defaultFilters);

    $('.ui.slider.slider-radius').slider({
      min: 0,
      max: 50,
      start: this.defaultFilters.radius,
      step: 1,
      // TODO
      onChange: () => {
        this.buildFilters();
      },
      onMove: (value: number, start: number) => {
        this.form.form('set values', {
          radius: start
        });
      }
    });

    $('.ui.range.slider.slider-rating').slider({
      min: 1,
      max: 10,
      start: this.defaultFilters.ratingFrom,
      end: this.defaultFilters.ratingTo,
      step: 1,
      labelDistance: 1,
      // TODO
      onChange: () => {
        this.buildFilters();
      },
      onMove: (value: number, start: number, end: number) => {
        this.form.form('set values', {
          ratingFrom: start,
          ratingTo: end,
        });
      }
    });
  }

  updateWorkplaceTypeForSelectedFilters(event: any, workplaceTypeId: string, equipmentId: string) {
    const isChecked = event.target.checked;
    const workplaceTypes = this.selectedFilters.workplaceType;
    const updateWorkplaceType = workplaceTypes.find(workplaceType => workplaceType.id === workplaceTypeId);
    if (updateWorkplaceType) {
      if (isChecked) {
        if (!updateWorkplaceType.equipment) {
          updateWorkplaceType.equipment = [equipmentId];
        } else if (!updateWorkplaceType.equipment.includes(equipmentId)) {
          updateWorkplaceType.equipment.push(equipmentId);
        }
      } else {
        if (updateWorkplaceType.equipment) {
          const index = updateWorkplaceType.equipment.indexOf(equipmentId);
          if (index > -1) {
            updateWorkplaceType.equipment.splice(index, 1);
          }
        }
      }
    }
    this.selectedFilters.workplaceType = workplaceTypes;
    this.changeTempFilters.emit(this.selectedFilters);
  }

  isChecked(workplaceTypeId: string, equipmentId: string) {
    let isChecked = false;
    const findWorkplaceType = this.selectedFilters.workplaceType.find(workplaceType => workplaceType.id === workplaceTypeId);
    if (findWorkplaceType && findWorkplaceType.equipment) {
      isChecked = findWorkplaceType.equipment.includes(equipmentId);
    }

    return isChecked;
  }

  private initFormAndControls() {
    this.form.form({
      fields: {
        name: { identifier: 'name' },
        tariffType: { identifier: 'tariffType' },
        cityId: { identifier: 'cityId' },
        radius: { identifier: 'radius' },
        ratingFrom: { identifier: 'ratingFrom' },
        ratingTo: { identifier: 'ratingTo' }
      },
      onSuccess: (event, fields) => {
        event.preventDefault();
      }
    });

    this.setDefaultFilters();

    $('.ui.checkbox').checkbox();
    $('.ui.dropdown').dropdown();
  }

  private buildFilters() {
    const formValues = this.form.form('get values');
    console.log('buildFilters', formValues);

    this.selectedFilters = {
      ...this.selectedFilters,
      ...formValues
    };
    console.log('buildFilters', this.selectedFilters);
  }

  private updateSliders() {
    $('.ui.slider.slider-radius').slider({
      start: this.selectedFilters.radius,
    });
    $('.ui.range.slider.slider-rating').slider({
      start: this.selectedFilters.ratingFrom,
      end: this.selectedFilters.ratingTo
    });
  }

  private updateWorkplaceTypesPriceSliders() {
    const workplaceTypes = this.selectedFilters.workplaceType;
    if (!workplaceTypes) {
      return;
    }

    workplaceTypes.forEach((workplaceType) => {
      // TODO
      // $(`.price-start-value${workplaceType.id}`).html(workplaceType.priceFrom);
      // $(`.price-to-value${workplaceType.id}`).html(workplaceType.priceTo);

      $(`.ui.range.slider.slider-price#${ workplaceType.id }`).slider({
        min: 0,
        max: 10000, // TODO: get max price
        start: workplaceType.priceFrom || 0,
        end: workplaceType.priceTo || 10000,  // TODO: get max price
        step: 1,
        // TODO
        onChange: () => {
          this.buildFilters();
        },
        onMove: (value: number, start: number, end: number) => {
          // TODO
          // workplaceType.priceFrom = start;
          // workplaceType.priceTo = end;

          // TODO
          // $(`.price-start-value${workplaceType.id}`).html(start);
          // $(`.price-to-value${workplaceType.id}`).html(end);
        }
      });
    });
  }
}
