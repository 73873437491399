import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as ApiModels from '../../api';
import { RentService } from '../../api';

import { ReservationCardOptions } from '../shared/reservation-card';

@Component({
  selector: 'app-reservation-confirmation',
  templateUrl: './reservation-confirmation.component.html',
  styleUrls: ['./reservation-confirmation.component.scss']
})
export class ReservationConfirmationComponent implements OnInit {
  rentIds: string[];
  items: ApiModels.RentCurrentWithWorkcenterAndEquipment[];
  notConfirmedReservationCardOptions: ReservationCardOptions;
  confirmedReservationCardOptions: ReservationCardOptions;

  agreement: boolean;
  confirmed: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private rentService: RentService
  ) {
    this.confirmed = false;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.rentIds = (typeof(params.ids) === 'string') ? [params.ids] : params.ids;
      this.rentService.reservedWithCenterAndEquipment(this.rentIds).toPromise().then(response => {
        if (response.length) {
          this.items = response.sort((prev, next) => new Date(prev.dateStart).getTime() - new Date(next.dateStart).getTime());
        }
      });
    });


    this.notConfirmedReservationCardOptions = {
      mode: 'preview',
      rules: {
        visible: true
      },
      equipment: {
        visible: true
      }
    };

    this.confirmedReservationCardOptions = {
      mode: 'preview',
      rules: {
        visible: true
      },
      actions: {
        footer: {
          visible: true
        }
      }
    };
  }

  confirm() {
    this.rentService.rentConfirm(this.rentIds).toPromise().then(response => {
      this.confirmed = true;
    });
  }

}
