import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';

declare const $: any;

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent implements OnInit, OnChanges {
  @Input() value: number[];
  @Input() minTime: number;
  @Input() maxTime: number;
  @Output() changeValue: EventEmitter<number[]>;

  popupVisible: boolean;
  displayValue: string;

  constructor() {
    this.value = [];
    this.changeValue = new EventEmitter<number[]>();
  }

  ngOnInit() {
    this.popupVisible = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.setValue(this.value);
    }
  }

  popupOpen() {
    if (this.popupVisible) {
      return;
    }

    this.popupVisible = true;
    setTimeout(() => {
      $('#inline_calendar-time_left').calendar({
        type: 'time',
        ampm: false,
        minDate: this.minTime ? new Date(this.HoursToDate(this.minTime)) : undefined,
        maxDate: this.maxTime ? new Date(this.HoursToDate(this.maxTime)) : undefined,
        minTimeGap: 60,
        endCalendar: $('#inline_calendar-time_right'),
        onSelect: (date, mode) => {
          this.setStartValue(date);
        }
      });

      $('#inline_calendar-time_right').calendar({
        type: 'time',
        ampm: false,
        minDate: this.minTime ? new Date(this.HoursToDate(this.minTime)) : undefined,
        maxDate: this.maxTime ? new Date(this.HoursToDate(this.maxTime)) : undefined,
        minTimeGap: 60,
        startCalendar: $('#inline_calendar-time_left'),
        onSelect: (date, mode) => {
          this.setEndValue(date);
        }
      });

      if (this.value) {
        $('#inline_calendar-time_left').calendar('set date', this.HoursToDate(this.value[0]), false, false);
        $('#inline_calendar-time_right').calendar('set date', this.HoursToDate(this.value[1]), false, false);
      }
    }, 0);
  }

  popupClose() {
    this.popupVisible = false;

    this.changeValue.emit(this.value);
  }

  clearValue() {
    $('#inline_calendar-time_left').calendar('clear');
    $('#inline_calendar-time_right').calendar('clear');
    this.value = [];
    this.displayValue = undefined;
  }

  private setValue(value: number[]) {
    if (!value) {
      this.value = [];
      return;
    }

    if (value[0]) {
      this.setStartValue(value[0]);
    }
    if (value[1]) {
      this.setEndValue(value[1]);
    }
  }

  private setStartValue(value: number | Date) {
    if (typeof(value) === 'number') {
      this.value[0] = value;
    } else if (value instanceof Date) {
      this.value[0] = value.getHours();
    }
    this.displayValue = this.value.map(time => time ? `${time}:00` : '').join(' - ');
  }

  private setEndValue(value: number | Date) {
    if (typeof(value) === 'number') {
      this.value[1] = value;
    } else if (value instanceof Date) {
      this.value[1] = value.getHours();
    }
    this.displayValue = this.value.map(time => time ? `${time}:00` : '').join(' - ');
  }

  private HoursToDate(value: number) {
    const today = new Date();
    const result = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      value,
      0
    );

    return result;
  }

}
