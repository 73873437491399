import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import * as ApiModels from '../../../api';

import { CreateProfileModel, UserType } from './registration.model';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  @Input() registrationOrganisationOnly: boolean;
  @Output() showLogin: EventEmitter<void>;
  @Output() closeClicked: EventEmitter<void>;

  startFormVisible: boolean;
  organisationFormVisible: boolean;
  userFormVisible: boolean;
  codeFormVisible: boolean;
  passwordFormVisible: boolean;
  endFormVisible: boolean;

  userType: UserType['name'];
  userProfile: CreateProfileModel;
  organisationProfile: ApiModels.OrganisationAdd;
  organisationId: string;

  currentStep: number;
  addStep: number;
  stepsCount: number;

  constructor() {
    this.showLogin = new EventEmitter();
    this.closeClicked = new EventEmitter();
    this.onStartFormShow();
  }

  ngOnInit() {
  }

  onStartFormShow() {
    this.currentStep = 1;
    this.addStep = 0;
    this.stepsCount = 5;
    this.startFormVisible = true;
    this.organisationFormVisible = false;
    this.userFormVisible = false;
    this.codeFormVisible = false;
    this.passwordFormVisible = false;
    this.endFormVisible = false;
  }

  onLoginShow() {
    this.showLogin.emit();
    this.onStartFormShow();
  }

  formTypeDetection(userType: UserType['name']) {
    this.userType = userType;

    if (this.userType === 'renter') {
      this.addStep = 0;
      this.stepsCount = 5;
      this.onUserFormShow();
    } else {
      this.addStep = 1;
      this.stepsCount = 6;
      this.onOrganisationFormShow();
    }
  }

  onOrganisationFormShow() {
    this.currentStep = 2;
    this.startFormVisible = false;
    this.userFormVisible = false;
    this.organisationFormVisible = true;
  }

  onOrganisationFormBackButtonClick() {
    this.onStartFormShow();
  }

  onOrganisationSet(organisation: ApiModels.OrganisationAdd) {
    this.organisationProfile = organisation;
    this.onUserFormShow();
  }

  onUserFormShow() {
    this.currentStep = 2 + this.addStep;
    this.startFormVisible = false;
    this.organisationFormVisible = false;
    this.codeFormVisible = false;
    this.userFormVisible = true;
  }

  onUserFormBackButtonClick() {
    if (this.userType === 'renter') {
      this.onStartFormShow();
    } else {
      this.onOrganisationFormShow();
    }
  }

  onUserProfileSet(userProfile: CreateProfileModel) {
    this.userProfile = userProfile;
    this.onCodeFormShow();
  }

  onCodeFormShow() {
    this.currentStep = 3 + this.addStep;
    this.userFormVisible = false;
    this.codeFormVisible = true;
  }

  onCodeFormBackButtonClick() {
    this.onUserFormShow();
  }

  onCodeSet(secretCode: string) {
    this.userProfile.secretCode = secretCode;
    this.onPasswordFormShow();
  }

  onPasswordFormShow() {
    this.currentStep = 4 + this.addStep;
    this.codeFormVisible = false;
    this.passwordFormVisible = true;
  }

  onRegistrationEnded(organisationId: string) {
    this.organisationId = organisationId;
    this.onEndFormShow();
  }

  onEndFormShow() {
    this.currentStep = 5 + this.addStep;
    this.passwordFormVisible = false;
    this.endFormVisible = true;
  }

  onCloseButtonClick() {
    this.closeClicked.emit();
    this.onStartFormShow();
  }
}
