import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';

import * as ApiModels from '../../../api';
import { WorkplaceTypeService } from '../../../api';

import { CarouselItem } from './custom-carousel.model';
import {
  advicesCarouselItems,
  articlesCarouselItems,
  masterClassesCarouselItems,
  objectGalleryCarouselItems,
  partnersClassesCarouselItems,
  recommendationsCarouselItems
} from './mocks';

@Component({
  selector: 'app-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['./custom-carousel.component.scss']
})
export class CustomCarouselComponent implements OnInit {
  @Input() items: CarouselItem[] | string[] | ApiModels.WorkplaceType[];
  @Input() type: 'advices' | 'articles' | 'filters' | 'master-classes' |
                        'object-gallery' | 'partners' | 'recommendations';

  config: OwlOptions;
  isDragging: boolean;

  constructor(
    private workplaceTypeService: WorkplaceTypeService
  ) { }

  ngOnInit() {
    this.initConfig();
    this.initData();
  }

  getPassedData(data: SlidesOutputData) {
  }

  getInitData(data: SlidesOutputData) {
  }

  private initConfig() {
    this.config = {
      loop: false,
      autoHeight: false,
      autoWidth: true,
      margin: 40,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      nav: true,
      navText: ['<span class="nav-shadow"></span>', '<span class="nav-shadow"></span>'],
      responsive: {
        0:    { items: 1 },
        480:  { items: 3 },
        678:  { items: 4 },
      },
      center: false,
    };
  }

  // mocks
  private initData() {
    switch (this.type) {
      case ('advices') :
        this.items = advicesCarouselItems;
        break;
      case ('articles') :
        this.items = articlesCarouselItems;
        break;
      case ('master-classes') :
        this.items = masterClassesCarouselItems;
        break;
      case ('partners') :
        this.items = partnersClassesCarouselItems;
        break;
      case ('recommendations') :
        this.items = recommendationsCarouselItems;
        break;
      case ('filters') :
        this.workplaceTypeService.get().toPromise().then(response => {
          // TODO раскомментировать
          // this.items = response.sort((prev, next) => prev.sortOrder - next.sortOrder);

          // TODO удалить
          this.items = (response as any).sort((prev, next) => prev.sortOrder - next.sortOrder)
            .map(item => {
              item.iconId = '0EAC3AF0-2AA0-46D8-BEB4-154F1B5D26F5';
              return item;
            });
        });
        break;
    }
  }
}
