import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';

import { SharedModule                     } from './shared/shared.module';

import { AuthService, TokenInterceptor    } from './auth';
import { AccountService, TokenService     } from '../api';
import { AppRoutingModule                 } from './app-routing.module';

import { AboutServiceModule               } from './about-service';
import { AppComponent                     } from './app.component';
import { AreaModule                       } from './area';
import { ContactsModule                   } from './contacts';
import { HomeModule                       } from './home';
import { MyReservationsModule             } from './my-reservations';
import { MyReviewsModule                  } from './my-reviews';
import { PageNotFoundModule               } from './page-not-found';
import { ProfileUserModule                } from './profile-user';
import { ProfileOrganisationModule        } from './profile-organisation';
import { ProfileLayoutComponent           } from './profile-layout';
import { ReservationCancellationModule    } from './reservation-cancellation';
import { ReservationConfirmationModule    } from './reservation-confirmation';
import { ReservationsWorkCenterModule     } from './reservations-workcenter';
import { SearchModule                     } from './search';
import { TariffModule                     } from './tariff';
import { WorkCenterModule                 } from './workcenter';
import { WorkCenterSearchModule           } from './workcenter-search';

registerLocaleData(localeRu, 'ru');

@NgModule({
  declarations: [
    AppComponent,
    ProfileLayoutComponent
  ],
  imports: [
    AboutServiceModule,
    AppRoutingModule,
    AreaModule,
    BrowserAnimationsModule,
    BrowserModule,
    ContactsModule,
    HomeModule,
    HttpClientModule,
    MyReservationsModule,
    MyReviewsModule,
    PageNotFoundModule,
    ProfileUserModule,
    ProfileOrganisationModule,
    ReservationCancellationModule,
    ReservationConfirmationModule,
    ReservationsWorkCenterModule,
    SearchModule,
    SharedModule,
    TariffModule,
    WorkCenterModule,
    WorkCenterSearchModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' },
    AuthService,
    AccountService,
    TokenService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
